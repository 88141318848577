import {order, validation} from '../services/services';
import {closeModal, openModal} from './modal';

function fastOrder(){
    const form = document.querySelector('.modal__order-form');
    const message = {
        success: '<p>Ваш заказ сформирован и направлен менеджеру.</p><p>Спасибо!</p>',
        fail: '<p>Ошибка отправки заявки. Попробуйте еще раз.</p><p>Спасибо!</p>'
    };

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        if(!validation(form)){
            return false;
        }

        const formData = new FormData(form);
        //console.log(formData);
        const json = JSON.stringify(Object.fromEntries(formData.entries()));

        order('/index/ajaxOrder', json)
            .then(data => {
                showThanksModal(message.success, data);
                if(data.data.type == 'cart'){
                    clearCart();
                }
                const basketCount = document.querySelector('.basket-count');
                basketCount.innerHTML = 0;
                basketCount.classList.add('no-items');
            })
            .catch((error) => {
                showThanksModal(message.fail, data);
            })
            .finally(() => {
                form.reset();
            });

        function showThanksModal(message, data){
            const modalPresend = document.querySelector('.modal__presend'),
                modalResult = document.querySelector('.modal__result');

            modalPresend.classList.remove('show');
            modalPresend.classList.add('hide');

            modalResult.classList.add('show');
            modalResult.classList.remove('hide');
            if(data.data.type == 'cart'){
                openModal('.modal__fon-message');
            }
        }

        function clearCart(){
            const itemsBlock = document.querySelector('.cart__items-block'),
                formBlock = document.querySelector('.cart__form-block'),
                noItemsBlock = document.querySelector('.no-items-cart');

            itemsBlock.remove();
            formBlock.remove();
            noItemsBlock.classList.remove('hide');

        }
    });
}

export default fastOrder;
