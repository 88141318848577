import {order} from '../services/services';
let searchData = []
function search(){
    const searchLines = document.querySelectorAll('.search-run')
    const pushButtons = document.querySelectorAll('.no-btn-push')
    pushButtons.forEach(elem => {
        elem.addEventListener('click', (ev) => {
            ev.preventDefault()
        })
    })

    searchLines.forEach(searchLine => {

        const searchField = searchLine.querySelector('.default-search')
        const resultBlock = searchLine.querySelector('.search-result-block')
        const resultWrapper = searchLine.querySelector('.search-result-wrapper')
        const searchArrowBtn = searchLine.querySelector('.search-back')

        searchField.addEventListener('input', event => {

            const valueSearch = event.target.value
            //console.log(searchData)
            if(valueSearch.length > 1){
                resultBlock.classList.remove('hide')
                getSearchData(valueSearch, searchLine)
            }else{
                resultBlock.classList.add('hide')
            }

        })

        searchArrowBtn.addEventListener('click', (element) => {
            searchArrowBtn.classList.add('hide-btn')
            searchField.value = ''
            searchField.classList.remove('focused-search')
            resultWrapper.innerHTML = ''
            resultBlock.classList.add('hide')
            searchLine.classList.remove('fixed-search')
            document.body.style.overflow = "";
        })

        searchField.onfocus = function() {
            this.classList.add('focused-search')
            searchArrowBtn.classList.remove('hide-btn')
            let windowWidth = window.innerWidth;
            if (windowWidth <= 768 && searchLine.classList.contains('middle-search')) {
                searchLine.classList.add('fixed-search')
                document.body.style.overflow = "hidden";
                window.scrollTo(pageYOffset, 0);
            }
        }
        searchField.onblur = function() {
            console.log(this.value.length)
            if(this.value.length === 0) {
                this.classList.remove('focused-search')
                searchArrowBtn.classList.add('hide-btn')
                searchLine.classList.remove('fixed-search')
                document.body.style.overflow = "";
            }
        }



    })

    const getSearchData = (searchString, searchField) => {
        //searchData.push(searchString)
        const sendData = {
            string: searchString
        }

        order('/zapchasti/search', sendData)
            .then(data => {
                searchData = data.data.result
                setSearchResult(searchData, searchField)
                console.log(searcField);
            })
            .catch((error) => {
                //setSearchResult([], searchField)
            })
            .finally(() => {

            });
    }

    const setSearchResult = (data, searchField) => {
        console.log(data)
        const resultBlock = searchField.querySelector('.search-result-block')
        const resultWrapper = searchField.querySelector('.search-result-wrapper')

        resultWrapper.innerHTML = ''

        if(data.length === 0){
            const noResultLine = document.createElement('div');
            noResultLine.classList.add('search-line')
            noResultLine.innerHTML = 'Ничего не найдено'
            resultWrapper.append(noResultLine)
        }else{
            //console.log(searchData)
            data.forEach(item => {
                const yesResultLine = document.createElement('div');
                yesResultLine.classList.add('search-line')

                const itemLink = document.createElement('a');

                itemLink.setAttribute('href', '/zapchasti/'+item.alias+'/')

                let itemTitle = ''

                if(item.type === 'tag'){
                    itemTitle = item.title+' ('+item.count+')'
                }
                if(item.type === 'category'){
                    itemTitle = item.title+' ('+item.count+')'
                }
                if(item.type === 'item'){
                    itemTitle = item.uid+' '+item.title
                }

                const itemTypeStr = document.createElement('span');
                itemTypeStr.classList.add('item-search-type')
                itemTypeStr.innerHTML = item.itemType

                itemLink.innerHTML = itemTitle
                //itemLink.append(itemTypeStr)

                yesResultLine.append(itemLink)
                yesResultLine.append(itemTypeStr)


                resultWrapper.append(yesResultLine)
            })
        }
    }

}



export default search;
