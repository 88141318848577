function openNav(navSelector, triggerSelector){
    const  navTrigger = document.querySelector(triggerSelector),
        nav = document.querySelector(navSelector);

    if(nav.classList.contains('show')){
        nav.classList.add('hide');
        nav.classList.remove('show');
        navTrigger.classList.remove('opened-nav');
        document.body.style.overflow = "";
    }else{
        nav.classList.add('show');
        nav.classList.remove('hide');
        navTrigger.classList.add('opened-nav');
        document.body.style.overflow = "hidden";
    }


}

function closeNav(navSelector, triggerSelector){
    const  navTrigger = document.querySelector(triggerSelector),
        nav = document.querySelector(navSelector);
    nav.classList.add('hide');
    nav.classList.remove('show');
    navTrigger.classList.remove('opened-nav');
    document.body.style.overflow = "";
}

function navigation(triggerSelector, navSelector) {

    let windowWidth = window.innerWidth;
    const navTrigger = document.querySelector(triggerSelector),
        nav = document.querySelector(navSelector);

    if(windowWidth <= 1250) {

        navTrigger.addEventListener('click', () => openNav(navSelector, triggerSelector));

        document.addEventListener('keydown', (e) => {
            if (e.code == 'Escape' && nav.classList.contains('show')) {
                closeNav(navSelector, triggerSelector);
            }
        });
    }else{
        closeNav(navSelector, triggerSelector);
        nav.classList.remove('hide');
        nav.classList.remove('show');
    }

}

export default navigation;
export {closeNav};
export {openNav};
