import {postData, sendCall, validation} from '../services/services';
import {closeModal, openModal} from './modal';

function forms(formSelector) {

    const forms = document.querySelectorAll(formSelector);

    const message = {
        success: '<p>Ваша заявка успешно отправлена.</p><p>Спасибо!</p>',
        fail: '<p>Ошибка отправки заявки. Попробуйте еще раз.</p><p>Спасибо!</p>'
    };

    forms.forEach(item => {
        bindPostData(item);
    });

    function bindPostData(form){
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            if(!validation(form)){
                return false;
            }

            const formData = new FormData(form);
            const json = JSON.stringify(Object.fromEntries(formData.entries()));
            //console.log(json);

            sendCall('/index/ajaxMessage', json)
                .then(data => {
                    showThanksModal(message.success);
                })
                .catch((error) => {
                    showThanksModal(message.fail);
                })
                .finally(() => {
                    form.reset();
                });

        });
    }

    function showThanksModal(message){
        const prevModalDialog = document.querySelector('.modal__fon-message');

        //prevModalDialog.classList.add('hide');
        openModal('.modal__fon-message');

        const thanksModal = prevModalDialog.querySelector('.modal__result');
        thanksModal.innerHTML = message;

    }
}

export default forms;
