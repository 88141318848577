function horizontal(selector){
    const slider = document.querySelector(selector);
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 1.5;
        slider.scrollLeft = scrollLeft - walk;
    });

    let moveEvent = null;
    slider.addEventListener('touchstart', (e) => {
        isDown = true;
        moveEvent = e;
        slider.classList.add('active');
        console.log(e.touches[0].pageX);
        startX = e.touches[0].pageX - slider.offsetLeft;
        console.log(startX);
        scrollLeft = slider.scrollLeft;
    }, {passive: true});
    slider.addEventListener('touched', () => {
        isDown = false;
        slider.classList.remove('active');
    }, {passive: true});
    slider.addEventListener('touchmove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.touches[0].pageX - slider.offsetLeft;
        const walk = (x - startX) * 1.5;
        slider.scrollLeft = scrollLeft - walk;
    }, {passive: true});


}

export default horizontal;
