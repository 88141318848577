'use strict';

import navigation from './modules/topnav';
import slider from './modules/slider';
import horizontal from './modules/horizontal';
import images from './modules/images';
import modal from './modules/modal';
import select from './modules/select';
import tabs from './modules/tabs';
import forms from './modules/forms';
import addModalOrser from './modules/card';
import fastOrder from './modules/fastOrder';
import pagination from './modules/pagination';
import ymap from './modules/ymap';
import loadMorePrice from './modules/loadMorePrice'
import openTags from './modules/openTags'
import mobileNavigation from './modules/mobileNavigation'
import search from './modules/search'

window.addEventListener('DOMContentLoaded', () => {

    window.addEventListener("popstate", function (e) {
        location.reload(true);
    }, false);

    let windowWidth = window.innerWidth;
    let itemsWrapper;
    if (document.querySelector('.items__wrapper')) {
        itemsWrapper = document.querySelectorAll('.items__wrapper');

        itemsWrapper.forEach(element => {
            element.onclick = function (event) {
                let target = event.target;

                let item = event.target.closest('.item__card-set');
                let orderBtn = event.target.closest('.item__card');
                if (!item) {
                    return;
                } else {
                    if (target.classList.contains('item__order-btn') || target.classList.contains('add-basket')) {
                        event.preventDefault();
                    }
                }

            };
        });


    }

    if (windowWidth <= 700 && document.querySelector('.index__slider')) {
        const sliderWrapper = document.querySelector('.index__slider');
        const sliderWidth = sliderWrapper.offsetWidth;
        const coef = 0.715;
        const sliderHeight = Math.round(sliderWidth * coef);
        sliderWrapper.style.height = sliderHeight + 'px';
    }


    const requiredField = document.querySelectorAll('.required');
    requiredField.forEach(element => {
        element.addEventListener('click', (e) => {
            const parentRequired = element.parentNode;
            if (element.classList.contains('no-valid')) {
                element.classList.remove('no-valid');
                if (parentRequired.querySelector('img')) {
                    parentRequired.querySelector('img').remove();
                }
            }
        });
    });

    navigation('.open__top-nav', '.header__bottom-block-mobile');
    window.addEventListener('resize', function (event) {
        navigation('.open__top-nav', '.header__bottom-block-mobile');

        if (windowWidth <= 700 && document.querySelector('.index__slider')) {
            const sliderWrapper = document.querySelector('.index__slider');
            const sliderWidth = sliderWrapper.offsetWidth;
            const coef = 0.715;
            const sliderHeight = Math.round(sliderWidth * coef);
            sliderWrapper.style.height = sliderHeight + 'px';
        }

    }, true);

    if (document.querySelector('.index__slider')) {
        slider({
            container: '.index__slider',
            nextArrow: '.index__slider-next',
            prevArrow: '.index__slider-prev',
            slide: '.index__slide',
            wrapper: '.index__slider-wrapper',
            field: '.index__slider-inner',
            arrows: false,
            dots: true,
            responsive: false,
            breakPoints: {},
            looping: true
        });

        window.addEventListener('resize', function (event) {
            slider({
                container: '.index__slider',
                nextArrow: '.index__slider-next',
                prevArrow: '.index__slider-prev',
                slide: '.index__slide',
                wrapper: '.index__slider-wrapper',
                field: '.index__slider-inner',
                arrows: false,
                dots: true,
                responsive: false,
                breakPoints: {},
                looping: true
            });
        }, true);
    }
    if (document.querySelector('.brand__slider')) {
        slider({
            container: '.brand__slider',
            nextArrow: '.brand__slider-next',
            prevArrow: '.brand__slider-prev',
            slide: '.brand__slide',
            wrapper: '.brand__slider-wrapper',
            field: '.brand__slider-inner',
            arrows: true,
            dots: false,
            looping: false,
            responsive: true,
            breakPoints: {
                1024: {
                    show: 5
                },
                768: {
                    show: 4
                },
                428: {
                    show: 3
                },
                320: {
                    show: 2
                }
            }
        });

        window.addEventListener('resize', function (event) {
            slider({
                container: '.brand__slider',
                nextArrow: '.brand__slider-next',
                prevArrow: '.brand__slider-prev',
                slide: '.brand__slide',
                wrapper: '.brand__slider-wrapper',
                field: '.brand__slider-inner',
                arrows: true,
                dots: false,
                looping: false,
                responsive: true,
                breakPoints: {
                    1024: {
                        show: 5
                    },
                    768: {
                        show: 4
                    },
                    428: {
                        show: 3
                    },
                    320: {
                        show: 2
                    }
                }
            });
        }, true);
    }
    /*if(document.querySelector('.tags__wrapper')) {
        horizontal('.tags__wrapper');
    }*/
    if (document.querySelector('.tags__index-wrapper--inside')) {
        if (windowWidth <= 1024) {
            const tagsLinks = document.querySelectorAll('.tags__link-inline');
            let linksSize = 0;
            tagsLinks.forEach(element => {
                linksSize = linksSize + element.offsetWidth;
            });
            const tagsInside = document.querySelector('.tags__index-wrapper--inside');
            tagsInside.style.width = (linksSize / 2) + 'px';
            horizontal('.tags__index-wrapper');
        }

    }

    if (document.querySelector('.dop-img__block')) {
        images('.item__page-img', '.dop-img');
    }

    if (document.querySelector('.item__order-btn')) {
        modal('.item__order-btn', '.modal__fon', '.modal__close');
    }

    if (document.querySelector('.select-wrapper')) {
        select('.select-wrapper', '.select-open', '.select-opened', '.sort__arrow', true);
    }

    if (document.querySelector('.select-wrapper')) {
        select('.sidebar__nav-block-single', '.sidebar__nav-block-open', '.sidebar__nav-block--list', '.sidebar__nav-block-arrow', false);
    }

    if (document.querySelector('.page__sidebar')) {
        if (windowWidth <= 768) {
            select('.page__sidebar', '.zapchsti__search-title', '.sidebar__nav-block', '.zapchsti__search-arrow', false);
        }
    }
    if (document.querySelector('.tabs__btns-btn')) {
        tabs('.tabs__btns-btn', '.tabs__body-line', '.tabs__btns-list', 'active-tab');
    }

    if (document.querySelector('.call-form')) {
        forms('.call-form');
        modal(null, '.modal__fon-message', '.modal__close');
    }

    if (document.querySelector('.item-data')) {
        addModalOrser();
    }

    if (document.querySelector('.modal__order-form')) {
        fastOrder();
    }

    if (document.querySelector('#param1')) {
        pagination();
    }

    if (document.querySelector('#price-more')) {
        loadMorePrice();
    }

    if (document.querySelector('#map_container')) {
        ymap();
    }

    if (document.querySelector('.side-tags-wrapper')) {
        openTags();
    }

    if (document.querySelector('.header__full-navigation')){
        mobileNavigation();
    }

    if (document.querySelector('.default-search')){
        search()
    }


});

