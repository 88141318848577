import {separator, order} from '../services/services';


const modalImg = document.querySelector('.modal__item-media img'),
    modalBlock = document.querySelector('.modal__item-wrapper'),
    modalTitle = document.querySelector('.modal__item-title'),
    modalUid = document.querySelector('.modal__item-article'),
    modalPrice = document.querySelector('.modal__item-price'),
    modalTotalSum = document.querySelector('.modal__total-sum'),
    modalId = document.querySelector('#modal-id'),
    modalType = document.querySelector('#modal-type'),
    modalPresend = document.querySelector('.modal__presend'),
    modalResult = document.querySelector('.modal__result'),
    flyCart = document.querySelector('.fly-cart'),
    countFly = flyCart.querySelector('.basket-count');
function addModalOrser(event) {

    const itemsWrapper = document.querySelectorAll('.items__wrapper');

    let itemImg = null,
        itemTitle,
        itemUid,
        itemPrice,
        toOrderBtn,
        toBasketBtn,
        itemId,
        itemType;

    itemsWrapper.forEach(element => {

        element.addEventListener('click', event => {

            let target = event.target;
            let item = event.target.closest('.item__card-set');

            if (!item && !target.classList.contains('item__order-btn') && !target.classList.contains('add-basket')){
                return;
            }

            if(item.querySelector('.item-img')){
                itemImg = item.querySelector('.item-img').getAttribute('data-img');
            }

            itemTitle = item.querySelector('.item-title').getAttribute('data-title');
            itemType = item.getAttribute('data-type');
            itemUid = item.querySelector('.item-uid').getAttribute('data-uid');
            itemPrice = item.querySelector('.item-price').getAttribute('data-price');
            toOrderBtn = item.querySelector('.item__order-btn');
            toBasketBtn = item.querySelector('.add-basket');
            itemId = toOrderBtn.getAttribute('data-id');

            let orderBtn = event.target.closest('.item__order-btn');


            if(target.classList.contains('item__order-btn')){
                clickOrderBtn({
                    itemImg,
                    itemTitle,
                    itemUid,
                    itemPrice,
                    toOrderBtn,
                    toBasketBtn,
                    itemId,
                    itemType
                });
            }

            if(target.classList.contains('add-basket')){
                setItemToBasket(itemId, itemType);
            }

            //console.log('tut');
            //card(item);
        });
    });



};

function clickOrderBtn(
    {
        itemImg,
        itemTitle,
        itemUid,
        itemPrice,
        toOrderBtn,
        toBasketBtn,
        itemId,
        itemType
    }){

    if(itemImg && itemImg != '/img/no-item-img.jpg'){
        modalImg.setAttribute('src', itemImg);
        modalBlock.classList.remove('fast-no-img');
    }else{
        modalImg.setAttribute('src', '#');
        modalBlock.classList.add('fast-no-img');
    }

    modalTitle.innerHTML = itemTitle;
    modalUid.innerHTML = itemUid;
    modalPrice.innerHTML = getPrice(itemPrice);
    modalTotalSum.innerHTML = getPrice(itemPrice);
    modalId.value = itemId;
    modalType.value = itemType;

    modalPresend.classList.add('show');
    modalPresend.classList.remove('hide');

    modalResult.classList.remove('show');
    modalResult.classList.add('hide');
}

function setItemToBasket(itemId, itemType){
    const json = {"item_id":itemId, "item_type":itemType};

    order('/index/toBascet', json)
        .then(data => {
            //console.log(data);
            setBuscetNum(data.data.bascetNum);
        })
        .catch((error) => {

        })
        .finally(() => {

        });
}

function getPrice(price){
    if(price == 0){
        return 'По запросу';
    }else{
        return separator(price)+'р';
    }
}

function setBuscetNum(number){
    const basketCount = document.querySelector('.basket-count');
    basketCount.innerHTML = number;
    const fixCount = document.querySelector('.fix-count');
    fixCount.innerHTML = number;
    basketCount.classList.remove('no-items');
    countFly.innerHTML = number;

    if(flyCart.classList.contains('blink-1')){
        clearTimeout(timer);
    }

    flyCart.classList.remove('hide');
    flyCart.classList.add('blink-1');
    let timer = setTimeout(function() {
        flyCart.classList.add('hide');
        flyCart.classList.remove('blink-1');
    }, 2100, clearTimeout(timer));

}




export default addModalOrser;
