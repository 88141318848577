import {order} from '../services/services';
function loadMorePrice(){

    const loadMore = document.querySelector('.load-more');

    const itemsWrapper = document.querySelector('.items__wrapper');
    const totalPages = Number(document.querySelector('#pagesCount').value);

    let pageUrl = window.location.href;
    let currentPage = Number(document.querySelector('#page').value);
    let loadMoreClick = false;

    //console.log(pageUrl);
    console.log(currentPage);

    if(loadMore) {
        loadMore.addEventListener('click', element => {

            console.log(totalPages, currentPage);
            loadMoreClick = true;
            if (totalPages > currentPage) {
                currentPage++;
                getMoreItems();
            }
            if (totalPages == currentPage) {
                loadMore.classList.add('hide');
            }

        });
    }

    function getMoreItems(){
        const sendData = {
            page: currentPage,
        }

        order('/prices', sendData)
            .then(data => {
                console.log(data);
                dopItems(data.data);
            })
            .catch((error) => {

            })
            .finally(() => {

            });
    }

    function dopItems(items){
        itemsWrapper.insertAdjacentHTML('beforeend', items);
        if (totalPages == currentPage) {
            itemsWrapper.append(lastItem);
            //lastItem.classList.remove('hide');
        }
    }


}

export default loadMorePrice;
