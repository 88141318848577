function images(baseImageSelector, imgSelector){
    const baseImage = document.querySelector(baseImageSelector),
    allImages = document.querySelectorAll(imgSelector);

    allImages.forEach(element => {
        element.addEventListener('click', () => {
            const imgHref = element.getAttribute('src');
            baseImage.setAttribute('src', imgHref);
        });

    });

}

export default images;
