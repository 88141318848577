function select(parentSelector, openSelector, bodySelector, sortArrow, openOut){
    const open = document.querySelectorAll(openSelector);

    open.forEach(element => {
       element.addEventListener('click', (event) => {
           //const parent = event.target.parentNode;
           const parent = event.target.closest(parentSelector);
           const body = parent.querySelector(bodySelector);
           const arrow = parent.querySelector(sortArrow);
           const dopSpan = element.querySelector('span');
           let selectOptions, selectField, firstWord, selectFormField;

           if(openOut) {
               selectOptions = parent.querySelectorAll('[data-select]');
               selectField = parent.querySelector('.select-open-title');
               selectFormField = parent.querySelector('.select-field');
               firstWord = selectField.innerHTML;
           }

           //console.log(selectOptions);

           if(parent.classList.contains('a-open')){
               body.classList.remove('show');
               body.classList.add('hide');
               parent.classList.remove('a-open');
               arrow.classList.remove('rotate-arrow');
           }else{
               body.classList.remove('hide');
               body.classList.add('show');
               parent.classList.add('a-open');
               arrow.classList.add('rotate-arrow');
           }

           if(openOut) {
               document.addEventListener('click', e => {
                   if (e.target !== parent && e.target != element && parent.classList.contains('a-open') && e.target !== arrow && e.target !== dopSpan) {
                       close();
                   }
               });
           }

           function close(){
               body.classList.remove('show');
               body.classList.add('hide');
               parent.classList.remove('a-open');
               arrow.classList.remove('rotate-arrow');
           }

           if(openOut) {
               selectOptions.forEach(selectElement => {
                  selectElement.addEventListener('click', (e) => {
                      const selectData = selectElement.getAttribute('data-select');
                      const selectText = selectElement.innerHTML;
                      selectField.innerHTML = selectText;
                      selectFormField.value = selectData;
                      element.classList.add('black');
                  })
               });
           }
       });
    });

}

export default select;
