function openTags(){
    if(document.querySelector('.side-tags-wrapper')){
        const tagsWrapper = document.querySelectorAll('.side-tags-wrapper')

        tagsWrapper.forEach(elem => {
            if(elem.querySelector('.show-all-tags')) {
                const showAll = elem.querySelector('.show-all-tags')
                const allTags = elem.querySelector('.all-tags-block')
                const btnText = elem.querySelector('.show-all-tags-text')
                const btnArrow = elem.querySelector('.sidebar__tags-arrow')

                showAll.addEventListener('click', () => {
                    if (showAll.classList.contains('opened-tags')) {
                        showAll.classList.remove('opened-tags')
                        btnText.innerHTML = 'все теги'
                        btnArrow.classList.remove('rotate-arrow')
                        allTags.classList.add('hide')
                    } else {
                        showAll.classList.add('opened-tags')
                        btnText.innerHTML = 'скрыть теги'
                        btnArrow.classList.add('rotate-arrow')
                        allTags.classList.remove('hide')
                    }
                })
            }
        })


    }

}

export default openTags;
