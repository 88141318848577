import {order} from '../services/services';
function pagination(){

    const paginationBlock = document.querySelector('.pagination');
    let paginationLinks;
    if(paginationBlock) {
        paginationLinks = paginationBlock.querySelectorAll('.plink');
    }
    const prevLink = document.querySelector('.preva');
    const nextLink = document.querySelector('.nexta');
    const loadMore = document.querySelector('.load-more');
    const pageType = document.querySelector('#pageType').value;
    const pageRealUrl = document.querySelector('#pageUrl').value;

    const param1 = document.querySelector('#param1').value;
    const param2 = document.querySelector('#param2').value;
    const itemsWrapper = document.querySelector('.items__wrapper');
    const totalPages = Number(document.querySelector('#pagesCount').value);
    const sortLinks = document.querySelectorAll('.select-sort');
    const lastItem = document.querySelector('.item__card-last');
    if(lastItem.classList.contains('hide')){
        lastItem.remove();
    }
    let sortBy = 'price';
    let sortByOrder = 'asc';

    let pageUrl = window.location.href;
    let currentPage = Number(document.querySelector('#page').value);
    let loadMoreClick = false;

    pageUrl = pageUrl.replace('page-'+currentPage+'/','');

    //console.log(pageUrl);
    //console.log(currentPage);

    if(paginationBlock) {
        paginationLinks.forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                const parentLink = element.parentNode;

                if (parentLink.classList.contains("prev")) {
                    if (currentPage > 1) {
                        currentPage--;
                    }
                    setPaginationNumbers();
                    setAciveClass();
                    if(currentPage != 1) {
                        window.history.pushState(null, null, pageUrl + 'page-' + currentPage + '/');
                        //window.location.href = pageUrl + 'page-' + currentPage + '/';
                    }else{
                        window.history.pushState(null, null, pageUrl);
                        //window.location.href = pageUrl;
                    }
                    if(currentPage > 1) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                    //location.reload(true);
                } else if (parentLink.classList.contains("next")) {
                    if (totalPages > currentPage) {
                        currentPage++;
                        setPaginationNumbers();
                        setAciveClass();
                        if(currentPage != 1) {
                            window.history.pushState(null, null, pageUrl + 'page-' + currentPage + '/');
                            //window.location.href = pageUrl + 'page-' + currentPage + '/';
                        }else{
                            window.history.pushState(null, null, pageUrl);
                            //window.location.href = pageUrl;
                        }

                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        //location.reload(true);
                    }
                } else {
                    currentPage = +element.innerHTML;
                    if (currentPage >= totalPages) {
                        currentPage = totalPages;
                    }
                    setPaginationNumbers();
                    setAciveClass();
                    if(currentPage != 1) {
                        window.history.pushState(null, null, pageUrl + 'page-' + currentPage + '/');
                        //window.location.href = pageUrl + 'page-' + currentPage + '/';
                    }else{

                        window.history.pushState(null, null, pageUrl);
                        //window.location.href = pageUrl;
                    }
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //location.reload(true);
                }

                if (currentPage < totalPages) {
                    loadMore.classList.remove('hide');
                    lastItem.classList.add('hide');
                } else {
                    loadMore.classList.add('hide');
                    lastItem.classList.remove('hide');
                }

                //console.log(currentPage);
                getMoreItems('pagination');
            });
        });


        loadMore.addEventListener('click', element => {
            loadMoreClick = true;

            if (totalPages > currentPage) {
                currentPage++;
                setPaginationNumbers();
                setAciveClass();
                getMoreItems('more');
                window.history.pushState(null, null, pageUrl+'page-'+currentPage+'/');
            }
            if (totalPages == currentPage) {
                loadMore.classList.add('hide');
                lastItem.classList.remove('hide');
            }

        });
    }

    sortLinks.forEach(element => {
       element.addEventListener('click', (e) => {
           sortBy = element.getAttribute('data-select');
           sortByOrder = element.getAttribute('data-order');

           currentPage = 1;
           if(paginationBlock) {
               setPaginationNumbers();
               setAciveClass();
           }
           getMoreItems('pagination');
           window.history.pushState(null, null, pageUrl);

       });
    });

    function getMoreItems(type){
        const sendData = {
            param1: param1,
            param2: param2,
            page: currentPage,
            sort: sortBy,
            order: sortByOrder
        }

        let orderUrl = '/sale';
        if(pageType == 'zap'){
            orderUrl = '/zapchasti';
        }

        order(orderUrl, sendData)
            .then(data => {
                //console.log(data);
                if(type == 'pagination') {
                    changeItems(data.data);
                }
                if(type == 'more') {
                    dopItems(data.data);
                }

            })
            .catch((error) => {

            })
            .finally(() => {
                changeTable();
            });
    }

    function changeItems(items){
        itemsWrapper.innerHTML = items;
        //console.log(items);
        if (totalPages == currentPage) {
            itemsWrapper.append(lastItem);
            //lastItem.classList.remove('hide');
        }
    }

    function changeTable(){
        let tableItems = [];

        const itemsTable = document.querySelector('.table-price-block');
        const allItems = itemsWrapper.querySelectorAll('.item__card');
        const tableBody = document.querySelector('.price-body');
        tableBody.innerHTML = '';
        allItems.forEach((elem) => {
            if(elem.querySelector('.item__card-title')){
                const itemNewName = elem.querySelector('.item__card-title').innerHTML;
                const itemNewBrand = elem.querySelector('.item__card-brand-name').innerHTML;
                const itemNewUid = elem.querySelector('.item__card-uid').innerHTML;
                const itemNewPrice = elem.querySelector('.item__card-price').innerHTML;
                //console.log(itemNewPrice);
                if(itemNewPrice != 'По запросу') {
                    let tableLine = document.createElement('tr');
                    let tableLineTitle = document.createElement('td');
                    tableLineTitle.innerHTML = itemNewName;
                    tableLine.appendChild(tableLineTitle);

                    let tableLineBrand = document.createElement('td');
                    tableLineBrand.innerHTML = itemNewBrand;
                    tableLine.appendChild(tableLineBrand);

                    let tableLineUid = document.createElement('td');
                    tableLineUid.innerHTML = itemNewUid;
                    tableLine.appendChild(tableLineUid);

                    let tableLinePrice = document.createElement('td');
                    tableLinePrice.innerHTML = itemNewPrice;
                    tableLine.appendChild(tableLinePrice);

                    tableItems.push(tableLine);
                }
            }
        });

        if(tableItems.length) {
            tableItems.forEach(item => {
                tableBody.append(item);
            });
            itemsTable.classList.remove('hide');
        }else{
            itemsTable.classList.add('hide');
        }

    }

    function dopItems(items){
        itemsWrapper.insertAdjacentHTML('beforeend', items);

        if (totalPages == currentPage) {
            itemsWrapper.append(lastItem);
            //lastItem.classList.remove('hide');
        }
    }

    function setAciveClass(){

        paginationLinks.forEach((element, index) => {
            element.parentNode.classList.remove('active');

            const itemPaginationNumber = +element.innerHTML;

            if(currentPage == itemPaginationNumber){
                element.parentNode.classList.add('active');
            }
            if(currentPage > 1){
                prevLink.parentNode.classList.remove('disabled');
                if(currentPage < 3){
                    prevLink.setAttribute('href', pageRealUrl);
                }else{
                    prevLink.setAttribute('href', pageRealUrl+'page-'+(currentPage-1));
                }

            }else{
                prevLink.setAttribute('href', '#');
                prevLink.parentNode.classList.add('disabled');
            }
            if(currentPage >= totalPages){
                nextLink.setAttribute('href', '#');
                nextLink.parentNode.classList.add('disabled');
            }else{
                nextLink.setAttribute('href', pageRealUrl+'page-'+(currentPage+1));
                nextLink.parentNode.classList.remove('disabled');
            }

        });

    }

    function setPaginationNumbers(){
        let btnPaginatNum = 4;
        if(totalPages < 4){
            btnPaginatNum = totalPages;
        }

        let firstNumber = currentPage-2;
        let lastNumber = firstNumber+btnPaginatNum-1;
        if(firstNumber < 1){
            firstNumber = 1;
            lastNumber = firstNumber+btnPaginatNum-1;
        }
        if(lastNumber >= totalPages){
            firstNumber = totalPages - btnPaginatNum+1;
        }
        if(totalPages > 4) {
            let k = 1;
            for (let i = firstNumber; i < (firstNumber + btnPaginatNum); i++) {
                paginationLinks[k].innerHTML = i;
                paginationLinks[k].setAttribute('href', pageRealUrl+'page-'+i);
                k++;
                //console.log(i);
            }
        }

    }
}

export default pagination;
