import axios from "axios";

const postData = async (url, data) => {
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: data
    });

    return await res.json();
};

const getResource = async (url) => {
    const res = await fetch(url);

    if(!res.ok){
        throw new Error(`Could not fetch ${url}, status: ${res.status}`);
    }

    return await res.json();
};

const sendCall = async (url, data) => {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    axios.defaults.headers.common['Content-type'] = 'application/json';
    //console.log(data);
    try {
        const response = await axios.post(url, {data});
        return response;
    } catch (error) {
        console.error(error);
    }
}

const order = async (url, data) => {

    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    axios.defaults.headers.common['Content-type'] = 'application/json';
    //console.log(data);
    try {
        const response = await axios.post(url, {data});
        return response;
    } catch (error) {
        console.error(error);
    }
}

const separator = (str) => {
    let parts = (str + '').split('.'),
        main = parts[0],
        len = main.length,
        output = '',
        i = len - 1;

    while(i >= 0) {
        output = main.charAt(i) + output;
        if ((len - i) % 3 === 0 && i > 0) {
            output = ' ' + output;
        }
        --i;
    }

    if (parts.length > 1) {
        output += '.' + parts[1];
    }
    return output;
}

const validation = (form) => {
    const requiredFields = form.querySelectorAll('.required');
    let validateTrue = true;

    requiredFields.forEach(element => {
        const parentRequired = element.parentNode;
        const noValidImg = document.createElement('img');
        noValidImg.src = '/img/ico-26.png';
        noValidImg.classList.add('no-valid-img');

        if(element.value == ''){
            validateTrue = false;
            element.classList.add('no-valid');
            if(!parentRequired.querySelector('img')) {
                element.insertAdjacentElement('afterend', noValidImg);
            }
        }else{
            element.classList.remove('no-valid');
            if(parentRequired.querySelector('img')){
                parentRequired.querySelector('img').remove();
            }
            /*const removeImg = parentRequired.querySelector('img');
            removeImg.remove();*/
        }
    });

    return validateTrue;
}

export {sendCall};
export {postData};
export {getResource};
export {separator};
export {order};
export {validation};
