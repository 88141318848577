function mobileNavigation(){
    if(document.querySelector('.header__full-navigation')){

        const navButton = document.querySelector('.open__top-nav')
        navButton.addEventListener('click', (element) => {
            if(!navButton.classList.contains('opened-nav')){
                const allSeconLists = document.querySelectorAll('.second-mobile-list')
                const allThirthLists = document.querySelectorAll('.thirst-mobile-list')
                allSeconLists.forEach(elem => {
                    if(elem.classList.contains('second-step-active')){
                        elem.classList.remove('second-step-active')
                        elem.classList.remove('overflow-block')
                    }
                })
                allThirthLists.forEach(elem => {
                    if(elem.classList.contains('thirst-step-active')){
                        elem.classList.remove('thirst-step-active')
                    }
                })
            }
        })

        const openMoreLink = document.querySelectorAll('.link-prevent')
        openMoreLink.forEach(link => {

            link.addEventListener('click', (event) => {
                event.preventDefault()
            })
        })

        const navLine = document.querySelectorAll('.header__full-navigation-first')

        navLine.forEach(elem => {
            if(document.querySelector('.mobile-navigation-open')){
                const openSecond = elem.querySelector('.mobile-navigation-open')
                const secondNav = elem.querySelector('.second-mobile-list')


                if(secondNav){
                    const backToBrand = elem.querySelector('.back-to-brand')
                    openSecond.addEventListener('click', (event) => {
                        //console.log(secondNav)
                        //secondNav.classList.add('show')
                        secondNav.classList.add('second-step-active')
                    })
                    backToBrand.addEventListener('click', () => {
                        secondNav.classList.remove('second-step-active')
                    })

                    const openStepThree = elem.querySelector('.go-to-step-three')
                    if(openStepThree){
                        const thirthNav = elem.querySelector('.thirst-mobile-list')
                        const backToStepTwo = elem.querySelector('.back-to-two')
                        openStepThree.addEventListener('click', () => {
                            secondNav.classList.add('overflow-block')
                            thirthNav.classList.add('thirst-step-active')
                            secondNav.scrollTop = 0
                        })
                        backToStepTwo.addEventListener('click', () => {
                            thirthNav.classList.remove('thirst-step-active')
                            secondNav.classList.remove('overflow-block')
                        })
                    }
                    //console.log(openStepThree)
                }

            }

        })

    }

}

export default mobileNavigation;
