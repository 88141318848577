function openModal(modalSelector){
    const modal = document.querySelector(modalSelector);
    modal.classList.add('show');
    modal.classList.remove('hide');
    document.body.style.overflow = "hidden";

}

function closeModal(modalSelector){
    const modal = document.querySelector(modalSelector);

    modal.classList.add('hide');
    modal.classList.remove('show');
    document.body.style.overflow = "";
}

function modal(triggerSelector, modalSelector, closeSelector) {
    let modalTrigger;

    if(triggerSelector){
        modalTrigger = document.querySelectorAll(triggerSelector);
    }
    const modal = document.querySelector(modalSelector),
        modalCloseBtn = document.querySelectorAll(closeSelector);

    let itemsWrapper;
    if(document.querySelector('.items__wrapper')) {
        itemsWrapper = document.querySelectorAll('.items__wrapper');

        itemsWrapper.forEach(element => {
            element.addEventListener('click', event => {
                let target = event.target;
                let orderBtn = event.target.closest('.item__order-btn');
                if (!orderBtn){
                    return;
                }

                openModal('.modal__fon');

            });
        });




        /*modalTrigger.forEach(btn => {
            btn.addEventListener('click', () => openModal(modalSelector));
        });*/
    }

    modal.addEventListener('click', (e) => {
        if(e.target === modal || e.target.getAttribute('data-close') == ''){
            closeModal(modalSelector);
        }
    });

    document.addEventListener('keydown', (e) => {
        if(e.code == 'Escape' && modal.classList.contains('show')){
            closeModal(modalSelector);
        }
    });

    modalCloseBtn.forEach(element => {
        element.addEventListener('click', () => {
            closeModal(modalSelector);
        });
    });

}

export default modal;
export {closeModal};
export {openModal};
