function slider({
        container,
        slide,
        nextArrow,
        prevArrow,
        wrapper,
        field,
        arrows,
        dots,
        responsive,
        breakPoints,
        looping
    }) {

    const slides = document.querySelectorAll(slide),
        slider = document.querySelector(container),
        slidesWrapper = document.querySelector(wrapper),
        slidesField = document.querySelector(field);
    let windowWidth = window.innerWidth;

    const sliderWidth = window.getComputedStyle(slidesWrapper).width;
    let slideWidth = sliderWidth;
    let countSlides = 1;

    if(responsive){
        for (var key in breakPoints) {
            if(windowWidth >= key){
                countSlides = breakPoints[key]['show'];
            }
        }
        slideWidth = (deleteNotDigits(sliderWidth) / +countSlides)+'px';
    }

    let slideIndex = 1;
    let offset = 0;

    slidesField.style.width = deleteNotDigits(slideWidth) * slides.length + 'px';
    slidesField.style.display = 'flex';
    if(windowWidth > 1024) {
        slidesField.style.transition = '0.5s all';
    }else{
        slidesField.style.transition = '0.3s all';
    }

    slidesWrapper.style.overflow = 'hidden';

    slides.forEach(slide => {
        slide.style.width = slideWidth;
    });

    slider.style.position = 'relative';

    function moveSlide(){
        slidesField.style.transform = `translateX(-${offset}px)`;

    }

    function deleteNotDigits(str){
        return +str.replace('px','');
    }

    if(dots){
        const indicators = document.createElement('ol'),
            dots = [];
        indicators.classList.add('carousel-indicators');

        slider.append(indicators);

        for(let i = 0; i < slides.length; i++){
            const dot = document.createElement('li');
            dot.setAttribute('data-slide-to', i + 1);
            dot.classList.add('dot');
            if(i == 0){
                dot.classList.add('active-dot');
            }
            indicators.append(dot);
            dots.push(dot);
        }

        dots.forEach(dot => {
            dot.addEventListener('click', (e) => {
                const slideTo = e.target.getAttribute('data-slide-to');

                slideIndex = slideTo;
                offset = deleteNotDigits(slideWidth)  * (slideTo - 1);

                moveSlide();
                changeDot();
            });
        });

        function changeDot(){
            dots.forEach(dot => dot.classList.remove('active-dot'));
            dots[slideIndex - 1].classList.add('active-dot');
        }
    }

    if(arrows){
        const prev = document.querySelector(prevArrow),
            next = document.querySelector(nextArrow);

        next.addEventListener('click', () => {

            if(offset == deleteNotDigits(slideWidth) * (slides.length - countSlides)){
                if(looping){
                    offset = 0;
                }
            }else{
                offset += deleteNotDigits(slideWidth) ;
            }

            moveSlide();

            if(slideIndex == slides.length){
                if(looping){
                    slideIndex = 1;
                }
            }else{
                if(slideIndex == 1){
                    slideIndex = slideIndex+countSlides;
                }else{
                    slideIndex++;
                }
            }

            if(dots) {
                changeDot();
            }

            showArrows();
        });

        prev.addEventListener('click', () => {

            if(offset == 0){
                if(looping) {
                    offset += deleteNotDigits(slideWidth) * (slides.length - countSlides);
                }
            }else{
                offset -= deleteNotDigits(slideWidth) ;
            }

            moveSlide();

            if(slideIndex == 1){
                if(looping) {
                    slideIndex = slides.length;
                }
            }else{
                if(slideIndex == countSlides+1){
                    slideIndex = slideIndex-countSlides;
                }else{
                    slideIndex--;
                }

            }

            if(dots) {
                changeDot();
            }

            showArrows();

        });

        let moveEvent = null;
        slidesWrapper.addEventListener("touchstart", function (e) {
            moveEvent = e;
        }, {passive: true});
        slidesWrapper.addEventListener("touchmove", function (e) {
            if (moveEvent) {
                let moveSide = e.touches[0].pageX - moveEvent.touches[0].pageX;

                if(moveSide < 0){
                    if(moveSide < -30){
                        if(offset == deleteNotDigits(slideWidth) * (slides.length - countSlides)){
                            if(looping){
                                offset = 0;
                            }
                        }else{
                            offset += deleteNotDigits(slideWidth) ;
                        }

                        moveSlide();

                        if(slideIndex == slides.length){
                            if(looping){
                                slideIndex = 1;
                            }
                        }else{
                            if(slideIndex == 1){
                                slideIndex = slideIndex+countSlides;
                            }else{
                                slideIndex++;
                            }
                        }

                        if(dots) {
                            changeDot();
                        }

                        showArrows();
                        moveEvent = null;
                    }
                }else{
                    if(moveSide > 30){
                        if(offset == 0){
                            if(looping) {
                                offset += deleteNotDigits(slideWidth) * (slides.length - countSlides);
                            }
                        }else{
                            offset -= deleteNotDigits(slideWidth) ;
                        }

                        moveSlide();

                        if(slideIndex == 1){
                            if(looping) {
                                slideIndex = slides.length;
                            }
                        }else{
                            if(slideIndex == countSlides+1){
                                slideIndex = slideIndex-countSlides;
                            }else{
                                slideIndex--;
                            }

                        }

                        if(dots) {
                            changeDot();
                        }

                        showArrows();
                        moveEvent = null;
                    }
                }
            }
        }, {passive: true});

        slidesWrapper.addEventListener("touched", function (e) {
            moveEvent = null;
        }, {passive: true});

        function showArrows(){
            if(slideIndex > 1){
                prev.classList.add('show');
                prev.classList.remove('hide');
            }else{
                prev.classList.remove('show');
                prev.classList.add('hide');
            }

            if(slideIndex >= slides.length){
                next.classList.remove('show');
                next.classList.add('hide');
            }else{
                next.classList.add('show');
                next.classList.remove('hide');
            }
        }
    }
}

export default slider;
